import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button, Form, Row, Col, Spinner, Container } from 'react-bootstrap';
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import { sendOtpForVerificationAction, verifyOtpForVerificationAction } from '../../Action/user.action';

const EmailVerificationModal = ({ showModal, setshowModal, email }) => {
    const [otp, setOtp] = useState(['', '', '', '']);
    const [otpIs, setOtpIs] = useState('');
    const inputRefs = useRef([]);
    const loginData = !Cookies.get("loginSuccessStepMrMint", {
        domain: "mrmint.io",
    })
        ? []
        : JSON.parse(
            Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" })
        );

    const handleOtpChange = (value, index) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        if (value.length === 1 && index < otp.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    useEffect(() => {
        let otp1 = otp[0] + otp[1] + otp[2] + otp[3]

        setOtpIs(otp1);
    }, [otp])

    const verifyOtpForVerification = async () => {
        try {
            const data = {
                email: email,
                otp: otpIs
            };

            let res = await verifyOtpForVerificationAction(data);
            if (res.success) {
                toast.success(res.msg);
                loginData.is_email_verify = 1
                Cookies.set("loginSuccessStepMrMint", JSON.stringify(loginData))
                setTimeout(() => {
                    window.location.reload()
                }, 300)
            } else {
                toast.error(res.msg);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>

            <Modal
                show={showModal}
                onHide={() => setshowModal(false)}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="w-100 text-center">
                        <h5 className="mb-0">OTP Verification</h5>
                       
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body >
                    <Container className='d-flex flex-column justify-content-center align-items-center'>
                    <p className='text-center'>Enter code that we have sent to your email {email}</p>
                        {/* {loding == true && ( */}
                        <Row className="mb-4 d-flex justify-content-center ">

                            {otp.map((value, index) => (
                                <Col key={index} xs={2} className='d-flex justify-content-center align-items-center'>
                                    <Form.Control
                                        type="text"
                                        maxLength="1"
                                        value={value}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                        className='text-center'
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            if (/^\d*$/.test(inputValue)) {
                                                handleOtpChange(inputValue, index);
                                            }
                                        }}
                                    />
                                </Col>
                            ))}
                        </Row>
                        <Button variant="main2" className=" text-white w-50 " style={{ height: 45 }} onClick={verifyOtpForVerification}>Verify OTP</Button>
                        {/* )} */}
                    </Container>
                </Modal.Body>



            </Modal>
        </>
    );
};

export default EmailVerificationModal;

