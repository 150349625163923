import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { QrReader } from 'react-qr-reader';
import Swal from "sweetalert2";
import "./couponValidator.css";
import { VerifyQRCodeAction } from "../../Action/user.action";
import toast from "react-hot-toast";

const CouponValidator = () => {
    const [couponCode, setCouponCode] = useState('');
    const [validateRes, setValidateRes] = useState([]);
    const [selected, setSelected] = useState("environment");
    const [startScan, setStartScan] = useState(false);
    const [data, setData] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false);
    const [ldpType, setLdpType] = useState("LDP-1")

    useEffect(() => {
        if (couponCode) {
            setError('');
        }
    }, [couponCode]);

    const handleScan = (scanData) => {
        if (scanData) {
            setData(scanData);
            setCouponCode(scanData?.text || scanData);
            handleClose();
        }
    };

    const handleError = (err) => {
        setError('Error accessing camera. Please ensure permissions are granted and try again.');
        setStartScan(false);
    };

    const handleClose = () => {
        setStartScan(false);
    };

    const ValidateNow = async (code = couponCode) => {
        setError('');
        if (!code) {
            setError('Please enter coupon code');
            return;
        }

        try {
            setLoader(true);
            const res = await VerifyQRCodeAction({
                ldpType,
                couponCode: code
            });

            if (res.success) {
                Swal.fire({
                    title: res.msg,
                    text: res.data,
                    icon: "success"
                });
                toast.success(res.msg)
                setCouponCode('');
            } else {
                setError(res.msg);
                toast.error(res.msg)
            }

            setLoader(false);
        } catch (err) {
            setLoader(false);
            setError('Something went wrong, please try again.');
        }
    };

    return (
        <Container className="mt-5">
            <div>
                <Row>
                    <Col lg={3}></Col>
                    <Col lg={6}>
                        <div className="playerdtl_card py-4">
                            <div className="text-center">
                                <div>
                                    <div className="p-4 fieldbox">
                                        <h5>Coupon Validator</h5>
                                        <div className="scanner_wrapper mt-4">
                                            <Row className="justify-content-center mt-4">
                                                <Col md={6} className="mb-4 d-flex justify-content-center">
                                                    <div className="img_wrapper">
                                                        {startScan ? (
                                                            <div className="QrReader">
                                                                <QrReader
                                                                    constraints={{
                                                                        facingMode: selected
                                                                    }}
                                                                    onResult={handleScan}
                                                                    onError={handleError}
                                                                    className="qrrederBox p-0"
                                                                />
                                                            </div>
                                                        ) : (
                                                            <img
                                                                src="assets/images/scanner.png"
                                                                onClick={() => {
                                                                    setStartScan(true);
                                                                    setCouponCode('');
                                                                }}
                                                                className="img-fluid"
                                                                alt="QR Scanner"
                                                            />
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-center">
                                                <Col md={6}>
                                                    <Row className="mb-2">
                                                        <Col>
                                                            <Form.Select
                                                                value={ldpType}
                                                                onChange={(e) => setLdpType(e.target.value)}
                                                                className='py-2'
                                                            >
                                                                <option value="LDP-1">LDP-1</option>
                                                                <option value="LDP-2">LDP-2</option>
                                                            </Form.Select>
                                                        </Col>
                                                    </Row>
                                                    <Row className="justify-content-center">
                                                        <Col md={12}>
                                                            <div className="mb-3">
                                                                <Form.Control
                                                                    disabled={startScan}
                                                                    onChange={(e) => setCouponCode(e.target.value)}
                                                                    value={couponCode}
                                                                    type="text"
                                                                    placeholder="Enter or scan coupon code"
                                                                    className="custom_field"
                                                                />
                                                            </div>
                                                            <small style={{ color: '#d35c5c' }}>{error}</small>
                                                        </Col>
                                                    </Row>

                                                    {startScan ? (
                                                        <Button variant="primary-btn" onClick={handleClose}>Cancel</Button>
                                                    ) : (
                                                        <Button
                                                            variant="primary-btn"
                                                            disabled={loader}
                                                            onClick={() => ValidateNow()}
                                                        >
                                                            {loader ? 'Processing...' : 'Validate'}
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default CouponValidator;
