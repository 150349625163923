import Web3 from "web3";
import config from "../../config/config";
import toast from "react-hot-toast";

const validation = async () => {
    if (!window.ethereum) {
        return {
            status: false,
            message: "Please your connect web3 wallet"
        }
    }
    const web3 = new Web3(window.ethereum);
    let chainId = await web3.currentProvider.chainId;
    if (!chainId) {
        chainId = web3.currentProvider.getChainId();
    }
    if (chainId != config.chainId && web3.utils.toHex(chainId) != config.chainId) {
        return {
            status: false,
            message: "Please select BNB Smart chain"
        }
    }
    return {
        status: true,
        web3
    }
}

export const getBalanceBlockchain = async (walletAddress) => {
    try {
        let res = await validation();
        if (!res.status) {
            return res;
        }
        const web3 = res.web3;
        const ldpContract = new web3.eth.Contract(config.LDPContractABI, config.contractAddressLDP);

        let mtnContractAddress = await ldpContract.methods.MNT().call();
        let usdtContractAddress = await ldpContract.methods.USDT().call();

        const contract_mnt = new web3.eth.Contract(config.mrmintContractAbi, mtnContractAddress);
        let mntBalance = await contract_mnt.methods
            .balanceOf(walletAddress)
            .call();
        let mntAllowance = await contract_mnt.methods
            .allowance(walletAddress, config.contractAddressLDP)
            .call();

        const contract_usdt = new web3.eth.Contract(config.mrmintContractAbi, usdtContractAddress);
        let usdtBalance = await contract_usdt.methods
            .balanceOf(walletAddress)
            .call();
        let usdtAllowance = await contract_usdt.methods
            .allowance(walletAddress, config.contractAddressLDP)
            .call();

        let getBalace = await web3.eth.getBalance(walletAddress);
        let currentBal = parseFloat((parseInt(getBalace) / 10 ** 18).toFixed(6));

        let mnt = parseFloat((parseInt(mntBalance) / 10 ** 18).toFixed(4));
        let usdt = parseFloat((parseInt(usdtBalance) / 10 ** 18).toFixed(4));

        return {
            status: true,
            MNT: mnt,
            USDT: usdt,
            BNB: currentBal,
            mntAllowance: parseFloat((parseInt(mntAllowance) / 10 ** 18).toFixed(4)),
            usdtAllowance: parseFloat((parseInt(usdtAllowance) / 10 ** 18).toFixed(4)),
        }
    } catch (error) {
        return {
            status: false,
            message: error.toString()
        }
    }
}

export const getPayableAmountBlockchain = async (coin, planId, walletAddress) => {
    try {
        let res = await validation();
        if (!res.status) {
            return res;
        }
        const web3 = res.web3;
        const contract = new web3.eth.Contract(config.LDPContractABI, config.contractAddressLDP);
        let getLdpFeesInMNT = await contract.methods.getLdpFeesInMNT(planId).call();
        let ldpPlanAmountInUSDT = await contract.methods.ldpPlanAmountInUSDT(planId).call();
        let amt = parseFloat((parseInt(ldpPlanAmountInUSDT) / 10 ** 18).toFixed(4));
        if (coin == 'MNT') {
            amt = parseFloat((parseInt(getLdpFeesInMNT) / 10 ** 18).toFixed(4));
        }
        return {
            status: true,
            amount: amt
        }
    } catch (error) {
        return {
            status: false,
            message: error.toString()
        }
    }
}

export const approveTokenForLDP = async (coin, planId, walletAddress) => {
    try {
        let res = await validation();
        if (!res.status) {
            return res;
        }
        const web3 = res.web3;
        let getBalace = await web3.eth.getBalance(walletAddress);

        const ldpContract = new web3.eth.Contract(config.LDPContractABI, config.contractAddressLDP);

        let tokenContractAddress = await ldpContract.methods.MNT().call();
        if (coin == 'USDT') {
            tokenContractAddress = await ldpContract.methods.USDT().call();
        }

        let getLdpFeesInMNT = await ldpContract.methods.getLdpFeesInMNT(planId).call();
        let ldpPlanAmountInUSDT = await ldpContract.methods.ldpPlanAmountInUSDT(planId).call();
        let token = ldpPlanAmountInUSDT // parseFloat((parseInt(ldpPlanAmountInUSDT) / 10 ** 18).toFixed(4));
        if (coin == 'MNT') {
            token = getLdpFeesInMNT// parseFloat((parseInt(getLdpFeesInMNT) / 10 ** 18).toFixed(4));
        }

        const tokenContract = new web3.eth.Contract(config.mrmintContractAbi, tokenContractAddress);

        let trx = await tokenContract.methods.approve(config.contractAddressLDP, token.toString());

        let encodeData = trx.encodeABI();
        let gasPrice = await web3.eth.getGasPrice();

        //fees for approval & joinLDP trx
        let fees = ((47000 + 92000) * parseInt(gasPrice)) / 10 ** 18;
        let currentBal = parseFloat((parseInt(getBalace) / 10 ** 18).toFixed(6));
        if (fees > currentBal) {
            return {
                status: false,
                message: `Insufficient gas fee for transaction, Minimum ${parseFloat(fees).toFixed(6)} BNB balanace required for transaction`
            }
        }

        let gasLimit = await web3.eth.estimateGas({
            gasPrice: web3.utils.toHex(gasPrice),
            to: tokenContractAddress,
            from: walletAddress,
            data: encodeData,
        });

        const txData = await web3.eth.sendTransaction({
            gasPrice: web3.utils.toHex(gasPrice),
            gas: web3.utils.toHex(gasLimit),
            to: tokenContractAddress,
            from: walletAddress,
            data: encodeData,
        });

        return {
            status: true,
            hash: txData.transactionHash
        }
    } catch (error) {
        return {
            status: false,
            message: error.toString()
        }
    }
}


export const joinLdpBlockchain = async (coin, planId, walletAddress, ldpId) => {
    try {
        let res = await validation();
        if (!res.status) {
            return res;
        }
        const web3 = res.web3;
        let getBalace = await web3.eth.getBalance(walletAddress);

        const ldpContract = new web3.eth.Contract(config.LDPContractABI, config.contractAddressLDP);

        let tokenContractAddress = await ldpContract.methods.MNT().call();
        if (coin == 'USDT') {
            tokenContractAddress = await ldpContract.methods.USDT().call();
        }

        let trx = await ldpContract.methods.joinLdp(planId, tokenContractAddress, ldpId);

        let encodeData = trx.encodeABI();
        let gasPrice = await web3.eth.getGasPrice();

        //fees for joinLDP trx
        let fees = (92000 * parseInt(gasPrice)) / 10 ** 18;
        let currentBal = parseFloat((parseInt(getBalace) / 10 ** 18).toFixed(6));
        if (fees > currentBal) {
            return {
                status: false,
                message: `Insufficient gas fee for transaction, Minimum ${parseFloat(fees).toFixed(6)} BNB balanace required for transaction`
            }
        }

        let gasLimit = await web3.eth.estimateGas({
            gasPrice: web3.utils.toHex(gasPrice),
            to: config.contractAddressLDP,
            from: walletAddress,
            data: encodeData,
        });

        const txData = await web3.eth.sendTransaction({
            gasPrice: web3.utils.toHex(gasPrice),
            gas: web3.utils.toHex(gasLimit),
            to: config.contractAddressLDP,
            from: walletAddress,
            data: encodeData,
        });

        return {
            status: true,
            hash: txData.transactionHash
        }
    } catch (error) {
        console.log(error, "=====")
        switch (error.code) {
            case 4001:
                toast.error("User Rejected");
                break;
            case -32603:
                toast.error(
                    "Insufficient Gas fees"
                );
                break;
            case -32002:
                toast.error(
                    "Request Already Processing, Please check your Meta Mask wallet"
                );
                break;
            case "ERR_NETWORK":
                toast.error("We're experiencing technical difficulties.");
                break;
            default:
                break;
        }
        return {
            status: false,
            message: error.toString()
        }
    }
}

